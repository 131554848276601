// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  APP: '/app',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  app: {
    root: ROOTS.APP,
    one: `${ROOTS.APP}/one`,
    two: `${ROOTS.APP}/two`,
    three: `${ROOTS.APP}/three`,
    group: {
      root: `${ROOTS.APP}/group`,
      five: `${ROOTS.APP}/group/five`,
      six: `${ROOTS.APP}/group/six`,
    },
  },
};
